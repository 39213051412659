/* Skills */
    /* Font Family: 
    Amazon Ember
        font-family: 'Amazon Ember', sans-serif;
        font-family: 'Amazon Ember Cd RC', sans-serif;
        font-family: 'Amazon Ember Display', sans-serif;
        font-family: 'Amazon Ember Duospace', sans-serif;
        font-family: 'Amazon Ember Mono', sans-serif;
        font-family: 'Amazon Ember V2', sans-serif; */
        html {
            scroll-behavior: smooth;
            -webkit-text-size-adjust: 100%;
        }

        #skills {
            display: flex;
            width: 100vw;
            height: 900px;
            background-color: goldenrod;
            flex-direction: column;
            align-items: center;
            clip-path: polygon(0 0, 100% 0, 100% 75%, 0 93%);
            text-align: center;
            margin: 0 auto;
            overflow: hidden;
            
        }

        .skills-heading {
            color: white;
            margin-top: 50px;
            font-size: 50px;
            text-shadow: 2px 2px 2px black;
            letter-spacing: 5px;
            margin-left: 0 auto;
            margin-right: 0 auto;
            text-align: center;
        }
        
        .skills-container-heading {
            font-size: 30px;
            color: white;
            letter-spacing: 1px;
            text-align: center;
            text-shadow: 2px 2px 2px black;
            margin: 0 auto;
            width: 100vw;
        }

        .skill img {
            height: 70px;
            width: auto;
        }


        .skill caption {
            font-size: 20px;
            font-weight: 400;
            margin: 5px auto;
            padding: 0 auto;
            text-align: center;
            color: white;
        }

        .skill {
            display: flex;
            text-align: center;
            padding: 25px;
            flex-direction: column;
            height: 25px;
            justify-items:flex-start;
            align-items: center;
        }

        .skills-container {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: row;
            justify-items: center;
            place-items: center;
        }

        .backend-skills-container {
            margin-top: 0px;
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-direction: row;
            justify-items: center;
            place-items: center;
        }

        .backendskills-container-heading {
            font-size: 30px;
            color: white;
            letter-spacing: 1px;
            text-align: center;
            text-shadow: 2px 2px 2px black;
            margin: 0 auto;
            width: 100vw;
            margin-top: 110px;
        }

        @media screen and (max-width: 750px)  {
            .skill img {
                height: 40px;
                width: auto;
                padding: 0 auto;
                margin: 0 auto;
            }

            .skill caption {
                font-size: 15px;
                color: white;
                text-shadow: none;
                font-weight: 400;
            }

            .skills-container-heading {
                font-size: 30px;
            }

            #skills {
                height: 800px;
                letter-spacing: 1px;
            }

            .backendskills-container-heading {
                margin-top: 75px;
                padding: 0px;

            }

            .skill {
                padding: 10px;
            }

        }