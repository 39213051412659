/* Footer */
    /* Font Family: 
    Amazon Ember
        font-family: 'Amazon Ember', sans-serif;
        font-family: 'Amazon Ember Cd RC', sans-serif;
        font-family: 'Amazon Ember Display', sans-serif;
        font-family: 'Amazon Ember Duospace', sans-serif;
        font-family: 'Amazon Ember Mono', sans-serif;
        font-family: 'Amazon Ember V2', sans-serif; */

.footer-container {
    height: 100px;
    background-color: black;
    margin: 0 auto;
    padding: 0 auto;
    box-sizing: border-box;
    color: white;
    font-family: 'Amazon Ember', sans-serif;
    width: 100vw;
    text-align: center;
    display: block;
    bottom: 0;
}

#footer-arrow {
    color: white;
    padding: 30px;
}

#footer-arrow:hover {
    cursor: pointer;
}