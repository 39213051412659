/* Skills */
    /* Font Family: 
    Amazon Ember
        font-family: 'Amazon Ember', sans-serif;
        font-family: 'Amazon Ember Cd RC', sans-serif;
        font-family: 'Amazon Ember Display', sans-serif;
        font-family: 'Amazon Ember Duospace', sans-serif;
        font-family: 'Amazon Ember Mono', sans-serif;
        font-family: 'Amazon Ember V2', sans-serif; */

#about {
    height: 800px;
    background-color: goldenrod;
    
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.about-heading{
    color: white;
    padding-top: 60px;
    font-size: 50px;
    text-shadow: 3px 3px 5px black;
    letter-spacing: 5px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.profile-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    box-shadow: 3px 3px 7px black;
    margin-top: 40px;
    margin-bottom: 40px;
}

.about-summary {
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 25px;
    word-spacing: 3px;
    line-height: 30px;
    text-shadow: 1px 1px 1px black;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

#resume-button {
    padding: 7px;
    margin: 5px;
    font-size: 22px;
    font-weight: 700;
    color: goldenrod;
    background-color:white;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
    box-shadow: 2px 2px 5px black;
    border-color: goldenrod;
    text-decoration: none;
}

#resume-button:hover {
    color: white;
    background-color: goldenrod;
    cursor: pointer;
}

@media screen and (max-width: 750px)  {
    .profile-image {
        width: 30%;
    }

    .about-summary {
        width: 94%;
        font-size: 20px;
        line-height: normal;
        font-weight: 600;
    }
}