/* Skills */
    /* Font Family: 
    Amazon Ember
        font-family: 'Amazon Ember', sans-serif;
        font-family: 'Amazon Ember Cd RC', sans-serif;
        font-family: 'Amazon Ember Display', sans-serif;
        font-family: 'Amazon Ember Duospace', sans-serif;
        font-family: 'Amazon Ember Mono', sans-serif;
        font-family: 'Amazon Ember V2', sans-serif; */

    html {
        scroll-behavior: smooth;
        -webkit-text-size-adjust: 100%;
    }

    #projects {
        height: 2200px;
        margin-left: 25px;
        margin-top: 25px;
    }

     #projects h1 {
        color: black;
        text-align: center;
        margin: 0 auto;
        letter-spacing: 3px;
        font-size: 50px;
        padding-top: 30px;
    }

    #projects h2 {
        color: black;
        text-shadow: none;
        text-align: center;
        font-size: 25px;
        margin-bottom: 0px;
        padding-bottom: 10px;
        margin: 0;
    }

    .project-image {
        display: block;
        width: 50%;
        box-shadow: 5px 5px 5px black;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
    }

    .label {
        border: 2px solid #272341;
        padding: 4px;
        margin: 5px;
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        background-color:goldenrod;
        width: fit-content;
        box-shadow: 1px 1px 3px black;
        margin-top: 0px;
        padding-top: 0px;
    }

    .label-container {
        display: flex;
        margin-bottom: 0;
        padding-bottom: 0;
        justify-content: center;
    }

    p {
        font-size: 20px;
        margin: 5px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .label-end {
        padding: 5px;
        margin: 5px;
        font-size: 20px;
        font-weight: 700;
        color: goldenrod;
        background-color:white;
        width: fit-content;
        list-style: none;
        text-decoration: none;
        border: 2px solid black;
        margin-bottom: 50px;
    }

    .label-end:hover {
        color: white;
        background-color: goldenrod;
    }

    .label-end:hover {
        cursor: pointer;
    }

    .project-container {
        margin-bottom: 55px;
    }

    .collaborators {
        font-style: italic;
    }

    .project-heading {
        margin-bottom: 100px;
        margin: 50px;
    }


    @media screen and (max-width: 750px)  {
        #projects {
            margin-left: auto;
            margin-right: auto;
            height: 2300px;
        }

        .project-image {
            display: block;
            width: 85%;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            box-shadow: 2px 2px 5px black;
            margin-top: 5px;
            margin-bottom: 75px;
            
        }

        #projects h2 {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin: 5px;
            padding-bottom: 0px;
        }

        .label {
            border: 2px solid #272341;
            padding: 3px;
            margin: 3px;
            font-size: 15px;
            font-weight: 700;
            color: #fff;
            background-color:goldenrod;
            width: fit-content;
            box-shadow: 1px 1px 3px black;
        }

        .label-end {
            font-size: 20px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: auto;
            margin-bottom: 10px;
        }

        .label-container {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            padding: 10px;
            height: auto;
            flex-wrap: wrap;
         
        }

        p {
            text-align: center;
            font-size: 17px;
        }

   
    
    
    
        

    }

    

