/* Header */
    /* Font Family: 
    Amazon Ember
        font-family: 'Amazon Ember', sans-serif;
        font-family: 'Amazon Ember Cd RC', sans-serif;
        font-family: 'Amazon Ember Display', sans-serif;
        font-family: 'Amazon Ember Duospace', sans-serif;
        font-family: 'Amazon Ember Mono', sans-serif;
        font-family: 'Amazon Ember V2', sans-serif; */

        html {
            scroll-behavior: smooth;
            -webkit-text-size-adjust: 100%;
            height: 4575px;
        }

        * {
            font-family: 'Amazon Ember', sans-serif;
            font-size: 35px;
            color: black;
            box-sizing: border-box;
        }
        
        /* Navigation */
        #social-links {
            height: 60px;
            display: flex;
            justify-content: right;
            align-items: flex-end;
            margin-left: 60%;
        }
        
        #social-links a i {
            padding: 10px 30px;
            color: goldenrod;
        }
        
        #welcome-banner {
            height: 680px;
            margin-top: 250px;
            line-height: 35px;
            width: 100%;
        }
        
        h1 {
            color: rgb(37, 37, 37);
            font-family: 'Amazon Ember V2', sans-serif;
            font-size: 60px;
            text-align: left;
            margin-left: 150px;
        }
        
        span {
            color: goldenrod;
            font-family: 'Amazon Ember V2', sans-serif;
            font-size: 60px;
            text-align: left;
            letter-spacing: 3px;
        }

        @media screen and (max-width: 750px) {
            #social-links {
                height: 60px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                margin-left: 0;
            }
        
            #social-links i {
                    padding: 1px 1px;
                    color: goldenrod;
                    margin-right: 0;
                    margin-top: 30px;
            }

            html {
                background-color: white;
                overflow-x: hidden;
                height: 4575px;
            }

           

            
        }

