/* App */
    /* Font Family: 
    Amazon Ember
        font-family: 'Amazon Ember', sans-serif;
        font-family: 'Amazon Ember Cd RC', sans-serif;
        font-family: 'Amazon Ember Display', sans-serif;
        font-family: 'Amazon Ember Duospace', sans-serif;
        font-family: 'Amazon Ember Mono', sans-serif;
        font-family: 'Amazon Ember V2', sans-serif; */



* {
    margin-left: auto;
    margin-right: auto;
    font-family: 'Amazon Ember V2', sans-serif;
}

html {
    height: auto;
}
